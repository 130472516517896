import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const Kindergarten= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
        <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'center'}>
               Kindergarten<br/>
              </Typography>
              <Typography component='p' align={'justify'} color="text.secondary">
              <br/>Originally a German word, the literal translation of Kindergarten is “a garden for children.” <br />
              At its best, Kindergarten is more than just an opportunity for children to socialise and learn about colours and shapes. This is an important year in the life of youngsters! 
<br/>At National Public School, Yeshwanthpur, the Kindergarten programme was created with the vision of enabling young learners to be educated in unique, creative and contemporary ways. Students are encouraged to adopt a scientific attitude and ask questions, build their imagination and express themselves freely and clearly. Our goal is to teach the young minds not what to think, but how to think.
<br/>The Kindergarten classroom is a homogeneous instructional environment. Students are provided with a physical setting that promotes autonomy and work-spaces that encourage independent thinking
<br/>All our young learners are given well-designed opportunities and experiences that will help develop and fine tune their literacy, numeracy, inter-personal skills and creativity. The Kindergarten programme also empowers young minds to build their personalities through self-discipline and self-confidence.
<br />
              </Typography>
          </Grid>

        </Container>
        <Container maxWidth={0.78}  paddingTop={'0 !important'}>
            <Form />
        </Container>
    </Main>
  );
};

export default Kindergarten;
